import React from "react"

import Layout from "../components/layout"
import ProjectListing from "../components/projectListing"
import SEO from "../components/seo"

import Bates from "../images/Bates-color.svg"
import Frontline from "../images/frontline-icon.svg"
import SoundPress from "../images/soundpress.svg"
import Learn4Life from "../images/l4l-logo.png"


class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      projectCount: 6,
      buttonText: "View More Projects",
      buttonState: "short"
    };

    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    if(this.state.buttonState === "short"){
      this.setState({
        projectCount: 12,
        buttonText: "Show Fewer Projects",
        buttonState: "long"
      })
    }else{
      this.setState({
        projectCount: 6,
        buttonText: "View More Projects",
        buttonState: "short"
      })
    }
    
  }


  render(){
    return (
      <Layout>
        <SEO title="Home" />
        <section id="welcomeSection" className="section-description">
          <h1>Hello, I'm Jake. I am a Web Developer living in Bristol, CT.</h1>
          <p>
            I am currently the full time Marketing Technologist at Frontline
            Education and a part time Web Application Developer at Bates
            Technical College. Other work history can be found on LinkedIn. I
            specialize in WordPress theme and plugin development, as well as web
            application development with various technologies. I am constantly
            learning and I am currently learning React and building headless
            WordPress applications. Outside of work, I have a beautiful wife and
            two children and I love to play hockey.
          </p>
        </section>
        <ProjectListing projectCount={this.state.projectCount} />
        <button onClick={this.loadMore} className="btn btn-outlined btn-blue">
          {this.state.buttonText}
        </button>
        <section>
          <h2>I have built stuff for...</h2>
          <ul className="company-listing">
            <li className="company-logo">
              <img
                src={Frontline}
                alt="I have worked with Frontline Education"
              />
            </li>
            <li className="company-logo">
              <img
                src={Bates}
                alt="I have worked with Bates Technical College"
              />
            </li>
            <li className="company-logo">
              <img src={SoundPress} alt="I have worked with SoundPress" />
            </li>
            <li className="company-logo">
              <img
                src={Learn4Life}
                alt="I have worked with Learn4Life Charter Schools"
              />
            </li>
          </ul>
        </section>
      </Layout>
    )
  }
}
  


export default IndexPage
